import React from 'react'

const FormUndangan = ({ 
  // bukuTamuDigital,
  // setBukuTamuDigital,
  demoUndangan,
  setDemoUndangan,
  priaWanita,
  setPriaWanita,
  namaCpw,
  setNamaCpw,
  panggilanCpw,
  setPanggilanCpw,
  posisiCpw,
  setPosisiCpw,
  namaCpp,
  setNamaCpp,
  panggilanCpp,
  setPanggilanCpp,
  posisiCpp,
  setPosisiCpp,
  ayahCpw,
  setAyahCpw,
  ibuCpw,
  setIbuCpw,
  ayahCpp,
  setAyahCpp,
  ibuCpp,
  setIbuCpp,
  hariAkad,
  setHariAkad,
  jamAkad,
  setJamAkad,
  lokasiAkad,
  setLokasiAkad,
  mapsAkad,
  setMapsAkad,
  hariResepsi,
  setHariResepsi,
  jamResepsi,
  setJamResepsi,
  lokasiResepsi,
  setLokasiResepsi,
  mapsResepsi,
  setMapsResepsi,
  reqRomantis,
  setReqRomantis,
  reqLagu,
  setReqLagu,
  sosmedCpw,
  setSosmedCpw,
  sosmedCpp,
  setSosmedCpp,
  amplopDigital,
  setAmplopDigital,
  catatanUndangan,
  setCatatanUndangan,
  linkDrive,
  setLinkDrive,
  invitationLink,
  setInvitationLink,
   }) => {

    const handleHariAkad = (e) => {
      const selectedDate = e.target.value;
      setHariAkad(formatDate(selectedDate));
    };

    const handleHariResepsi = (e) => {
      const selectedDate = e.target.value;
      setHariResepsi(formatDate(selectedDate));
    };

    const formatDate = (dateString) => {
      // Membuat objek Date dari string yang dipilih
      const date = new Date(dateString);

      // Daftar nama hari dalam bahasa Indonesia
      const daysOfWeek = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

      // Daftar nama bulan dalam bahasa Indonesia
      const months = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 
        'September', 'Oktober', 'November', 'Desember'
      ];

      // Mengambil hari, tanggal, bulan, dan tahun
      const dayOfWeek = daysOfWeek[date.getDay()];
      const day = String(date.getDate()).padStart(2, '0'); // Menambahkan leading zero
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      // Mengembalikan format yang diinginkan
      return `${dayOfWeek}, ${day} ${month} ${year}`;
    };

  return (
    <>
    <h1 className='clientform--form-title'>
        Form Undangan Online
    </h1>
    <fieldset>
        <legend>Fitur Buku Tamu Digital<span>*</span></legend>
        <p>Pakai / Tidak Pakai</p>
        <input
        type="text"
        value={invitationLink}
        onChange={(e) => setInvitationLink(e.target.value)}
        required
        />
    </fieldset>
    <fieldset>
        <legend>Nomor Desain Undangan<span>*</span></legend>
        <input
        type="text"
        value={demoUndangan}
        onChange={(e) => setDemoUndangan(e.target.value)}
        required
        />
        <p>Nomor Desain silahkan pilih di <a href='https://undangan.tigadaracatering.id/' style={{'color':'#fff', 'textDecoration':'unset', 'backgroundColor':'#e93333'}}>undangan.tigadaracatering.id</a></p>
    </fieldset>
    <fieldset>
        <legend>Urutan Mempelai Dalam Undangan<span>*</span></legend>
        <p>Pria Dahulu / Wanita Dahulu</p>
        <input
        type="text"
        value={priaWanita}
        onChange={(e) => setPriaWanita(e.target.value)}
        required
        />
    </fieldset>
    <fieldset>
        <legend>Data Calon Pengantin Wanita (CPW)<span>*</span></legend>
        <div>
          <div>
            <p>Nama Lengkap</p>
            <input
            type="text"
            value={namaCpw}
            onChange={(e) => setNamaCpw(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Nama Panggilan</p>
            <input
            type="text"
            value={panggilanCpw}
            onChange={(e) => setPanggilanCpw(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Putri Ke-</p>
            <input
            type="text"
            value={posisiCpw}
            onChange={(e) => setPosisiCpw(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Nama Ayah</p>
            <input
            type="text"
            value={ayahCpw}
            onChange={(e) => setAyahCpw(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Nama Ibu</p>
            <input
            type="text"
            value={ibuCpw}
            onChange={(e) => setIbuCpw(e.target.value)}
            required
            />
          </div>
        </div>
    </fieldset>
    <fieldset>
        <legend>Data Calon Pengantin Pria (CPP)<span>*</span></legend>
        <div>
          <div>
            <p>Nama Lengkap</p>
            <input
            type="text"
            value={namaCpp}
            onChange={(e) => setNamaCpp(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Nama Panggilan</p>
            <input
            type="text"
            value={panggilanCpp}
            onChange={(e) => setPanggilanCpp(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Putra Ke-</p>
            <input
            type="text"
            value={posisiCpp}
            onChange={(e) => setPosisiCpp(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Nama Ayah</p>
            <input
            type="text"
            value={ayahCpp}
            onChange={(e) => setAyahCpp(e.target.value)}
            required
            />
          </div>
          <div>
            <p>Nama Ibu</p>
            <input
            type="text"
            value={ibuCpp}
            onChange={(e) => setIbuCpp(e.target.value)}
            required
            />
          </div>
        </div>
    </fieldset>
    <fieldset>
        <legend>Waktu & Tempat Pelaksanaan Akad/Pemberkatan</legend>
        <div>
          <div>
            <p>Hari & Tanggal :
              <br>
              </br><strong>{hariAkad}</strong>
            </p>
            <input
            type="date"
            onChange={handleHariAkad}
            required
            />
          </div>
          <div>
            <p>Jam Acara</p>
            <input
            type="text"
            value={jamAkad}
            onChange={(e) => setJamAkad(e.target.value)}
            />
          </div>
          <div>
            <p>Lokasi Acara</p>
            <input
            type="text"
            value={lokasiAkad}
            onChange={(e) => setLokasiAkad(e.target.value)}
            />
          </div>
          <div>
            <p>Link Google Maps</p>
            <input
            type="text"
            value={mapsAkad}
            onChange={(e) => setMapsAkad(e.target.value)}
            />
          </div>
        </div>
    </fieldset>
    <fieldset>
        <legend>Waktu & Tempat Pelaksanaan Resepsi</legend>
        <div>
          <div>
            <p>Hari & Tanggal :
              <br>
              </br><strong>{hariResepsi}</strong>
            </p>
            <input
            type="date"
            onChange={handleHariResepsi}
            required
            />
          </div>
          <div>
            <p>Jam Acara</p>
            <input
            type="text"
            value={jamResepsi}
            onChange={(e) => setJamResepsi(e.target.value)}
            />
          </div>
          <div>
            <p>Lokasi Acara</p>
            <input
            type="text"
            value={lokasiResepsi}
            onChange={(e) => setLokasiResepsi(e.target.value)}
            />
          </div>
          <div>
            <p>Link Google Maps</p>
            <input
            type="text"
            value={mapsResepsi}
            onChange={(e) => setMapsResepsi(e.target.value)}
            />
          </div>
        </div>
    </fieldset>
    <fieldset>
        <legend>Request Kata-kata romantis</legend>
        <input
        type="text"
        value={reqRomantis}
        onChange={(e) => setReqRomantis(e.target.value)}
        />
    </fieldset>
    <fieldset>
        <legend>Request Musik Untuk Backsound Undangan</legend>
        <input
        type="text"
        value={reqLagu}
        onChange={(e) => setReqLagu(e.target.value)}
        />
    </fieldset>
    <fieldset>
        <legend>Sosial Media Mempelai</legend>
        <div>
          <div>
            <p>Instagram/Whatsapp/Lainnya milik CPW</p>
            <textarea
            type="text"
            value={sosmedCpw}
            onChange={(e) => setSosmedCpw(e.target.value)}
            />
          </div>
          <div>
            <p>Instagram/Whatsapp/Lainnya milik CPP</p>
            <textarea
            type="text"
            value={sosmedCpp}
            onChange={(e) => setSosmedCpp(e.target.value)}
            />
          </div>
        </div>
    </fieldset>
    <fieldset>
        <legend>Nomor Rekening Untuk Amplop Digital</legend>
        <p>OVO/Dana/Gopay/BCA/Lainnya</p>
        <textarea
        type="text"
        value={amplopDigital}
        onChange={(e) => setAmplopDigital(e.target.value)}
        />
    </fieldset>
    <fieldset>
        <legend>Catatan Tambahan Untuk Undangan (Jika ada)</legend>
        <textarea
        type="text"
        value={catatanUndangan}
        onChange={(e) => setCatatanUndangan(e.target.value)}
        />
    </fieldset>
    <fieldset>
        <legend>Link Foto Cetak</legend>
        <p>Masukkan Link Google Drive. <i>Harap link yang diberikan tidak di PRIVATE.</i></p>
        <textarea
        type="text"
        value={linkDrive}
        onChange={(e) => setLinkDrive(e.target.value)}
        />
        <p>Catatan Foto Undangan:</p>
        <ol>
          <li>1 Pose Foto Sendiri Mempelai Pria untuk di Profile</li>
          <li>1 Pose Foto Sendiri Mempelai Wanita untuk di Profile</li>
          <li>Foto Untuk Gallery Undangan Max. 10 Foto  (untuk slide show)</li>
          <li>Foto untuk Undangan dapat dikirim via email ke fotoundangan@tigadaracatering.id</li>
          <li>Foto untuk di meja galeri foto event dapat dikirim via email ke fotocetak@tigadaracatering.id</li>
        </ol>
    </fieldset>
    </>
  )
}

export default FormUndangan