import React, { useEffect } from 'react';


const FormOrder = ({ 
    // Nama Pemesan
    name, 
    setName,
    // Nomor HP/WA
    phone,
    setPhone,
    // Kalkulator Pembayaran
    totalHarga,
    setTotalHarga,
    downPayment,
    setDownPayment,
    sisaPembayaran,
    setSisaPembayaran,
    // Jenis Pesanan
    orderType,
    setOrderType,
    // Nama Paket
    packageName,
    setPackageName,
    // Jumlah Pax
    pax,
    setPax,
    // Nama Mempelai
    namaPria,
    setNamaPria,
    namaWanita,
    setNamaWanita,
    // IG Mempelai
    igPria,
    setIgPria,
    igWanita,
    setIgWanita,
    // Email Mempelai
    emailPria,
    setEmailPria,
    emailWanita,
    setEmailWanita,
    // Alamat Akad
    akadAddress,
    setAkadAddress,
    akadMaps,
    setAkadMaps,
    akadDate,
    setAkadDate,
    akadTime,
    setAkadTime,
    // Alamat Resepsi
    resepsiAddress,
    setResepsiAddress,
    resepsiMaps,
    setResepsiMaps,
    resepsiDate,
    setResepsiDate,
    resepsiTime,
    setResepsiTime,
    // Nama Paket
    menuPackage,
    setMenuPackage,
    // IG Pemesan
    igOrder,
    setIgOrder,
    // Alamat Pengiriman
    deliveryAddress,
    setDeliveryAddress,
    deliveryMaps,
    setDeliveryMaps,
    // Tanggal Pengiriman
    deliveryDate,
    setDeliveryDate,
    // Jam Pengiriman
    deliveryTime,
    setDeliveryTime,
  }) => {

    useEffect(() => {
        // Menghitung sisa pembayaran
        const total = parseFloat(totalHarga) || 0;
        const downPaymentValue = parseFloat(downPayment) || 0;
        const calculatedSisa = total - downPaymentValue;
    
        setSisaPembayaran(calculatedSisa.toFixed(2)); // Membulatkan hingga dua desimal
      }, [totalHarga, downPayment, setSisaPembayaran]);
    

    // useEffect(() => {
    //     // Menghitung sisa berdasarkan totalHargaPaket dan downPayment
    //     setSisaPembayaran(totalHarga - downPayment);
    //   }, [totalHarga, downPayment, setSisaPembayaran]);

      
  return (
    <>
    <h1 className='clientform--form-title'>
        Form Pemesanan
    </h1>
    <fieldset>
        <legend>Nama Pemesan <span>*</span></legend>
        <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        />
    </fieldset>
    <fieldset>
        <legend>Nomor HP/WA <span>*</span></legend>
        <input
        type="number"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
        />
    </fieldset>


    <fieldset className='konfirmasi'>
    <legend>Konfirmasi Pembayaran <span>*</span></legend>
        <div>
        <label>Total Harga Paket:</label>
        <input
            type="number"
            value={totalHarga}
            onChange={(e) => setTotalHarga(e.target.value)}
            required
        />
        </div>
        <div>
        <label>Down Payment:</label>
        <input
            type="number"
            value={downPayment}
            onChange={(e) => setDownPayment(e.target.value)}
            required
        />
        </div>
        <div>
        <label>Sisa Pembayaran:</label>
        <input
            type="number"
            className='sisa-pemb'
            value={sisaPembayaran}
            onChange={(e) => setSisaPembayaran(e.target.value)}
            readOnly
        />
        </div>
    </fieldset>


    <fieldset>
      <legend>Jenis Pesanan <span>*</span></legend>
      <select value={orderType} onChange={(e) => setOrderType(e.target.value)} required>
        <option value="">Pilih Jenis Pesanan</option>
        <option value="Wedding">Wedding</option>
        <option value="Nasi Box Prasmanan">Nasi Box / Prasmanan</option>
      </select>
    </fieldset>
    {orderType === 'Wedding' && (
        <>
        <fieldset className='subset'>
            <h3>Nama Mempelai</h3>
            <div className='vw50'>
                <fieldset>
                    <legend>Pria <span>*</span></legend>
                    <input
                    type="text"
                    value={namaPria}
                    onChange={(e) => setNamaPria(e.target.value)}
                    required
                    />
                </fieldset>
                <fieldset>
                    <legend>Wanita <span>*</span></legend>
                    <input
                    type="text"
                    value={namaWanita}
                    onChange={(e) => setNamaWanita(e.target.value)}
                    required
                    />
                </fieldset>

            </div>
        </fieldset>
        <fieldset className='subset'>
            <h3>Instagram Mempelai</h3>
            <div className='vw50'>
                <fieldset>
                    <legend>Pria</legend>
                    <input
                    type="text"
                    value={igPria}
                    onChange={(e) => setIgPria(e.target.value)}
                    // required
                    />
                </fieldset>
                <fieldset>
                    <legend>Wanita</legend>
                    <input
                    type="text"
                    value={igWanita}
                    onChange={(e) => setIgWanita(e.target.value)}
                    // required
                    />
                </fieldset>

            </div>
        </fieldset>
        <fieldset className='subset'>
            <h3>Email Mempelai</h3>
            <div className='vw50'>
                <fieldset>
                    <legend>Pria <span>*</span></legend>
                    <input
                    type="text"
                    value={emailPria}
                    onChange={(e) => setEmailPria(e.target.value)}
                    required
                    />
                </fieldset>
                <fieldset>
                    <legend>Wanita <span>*</span></legend>
                    <input
                    type="text"
                    value={emailWanita}
                    onChange={(e) => setEmailWanita(e.target.value)}
                    required
                    />
                </fieldset>
            </div>
            <p>Wajib diisi untuk pengiriman E-Kontrak</p>
        </fieldset>
        <fieldset>
            <legend>Nama Paket Pernikahan: <span>*</span></legend>
            <input
            type="text"
            value={packageName}
            onChange={(e) => setPackageName(e.target.value)}
            required
            />
        </fieldset>
        <fieldset>
            <legend>Jumlah Tamu <span>*</span></legend>
            <input
            type="text"
            value={pax}
            onChange={(e) => setPax(e.target.value)}
            required
            />
        </fieldset>
        <fieldset className='subset'>
            <h3>Akad / Pemberkatan</h3>
            <fieldset>
                <legend>Lokasi <span>*</span></legend>
                <textarea
                type="text"
                value={akadAddress}
                onChange={(e) => setAkadAddress(e.target.value)}
                required
                />
            </fieldset>
            <fieldset>
                <legend>Link Share Location</legend>
                <input
                type="text"
                value={akadMaps}
                onChange={(e) => setAkadMaps(e.target.value)}
                // required
                />
            </fieldset>
            <div className='vw50'>
                <fieldset>
                    <legend>Tanggal <span>*</span></legend>
                    <input
                    type="date"
                    value={akadDate}
                    onChange={(e) => setAkadDate(e.target.value)}
                    required
                    />
                </fieldset>
                <fieldset>
                    <legend>Jam <span>*</span></legend>
                    <input
                    type="time"
                    value={akadTime}
                    onChange={(e) => setAkadTime(e.target.value)}
                    required
                    />
                </fieldset>

            </div>
        </fieldset>
        <fieldset className='subset'>
            <h3>Resepsi <span>*</span></h3>
            <fieldset>
                <legend>Lokasi <span>*</span></legend>
                <textarea
                type="text"
                value={resepsiAddress}
                onChange={(e) => setResepsiAddress(e.target.value)}
                required
                />
            </fieldset>
            <fieldset>
                <legend>Link Share Location</legend>
                <input
                type="text"
                value={resepsiMaps}
                onChange={(e) => setResepsiMaps(e.target.value)}
                // required
                />
            </fieldset>
            <div className='vw50'>
                <fieldset>
                    <legend>Tanggal <span>*</span></legend>
                    <input
                    type="date"
                    value={resepsiDate}
                    onChange={(e) => setResepsiDate(e.target.value)}
                    required
                    />
                </fieldset>
                <fieldset>
                    <legend>Jam <span>*</span></legend>
                    <input
                    type="time"
                    value={resepsiTime}
                    onChange={(e) => setResepsiTime(e.target.value)}
                    required
                    />
                </fieldset>

            </div>
        </fieldset>
        </>
      
    )}
    {orderType === 'Nasi Box Prasmanan' && (
    //   <div>
    //     <label>Menu Paket Yang Dipilih:</label>
    //     <input
    //       type="text"
    //       value={menuPackage}
    //       onChange={(e) => setMenuPackage(e.target.value)}
    //       required
    //     />
    //   </div>
    <>
        <fieldset>
            <legend>Nama Menu Paket <span>*</span></legend>
            <input
            type="text"
            value={menuPackage}
            onChange={(e) => setMenuPackage(e.target.value)}
            required
            />
        </fieldset>
        <fieldset>
            <legend>Jumlah Order (Box / Pax) <span>*</span></legend>
            <input
            type="text"
            value={pax}
            onChange={(e) => setPax(e.target.value)}
            required
            />
        </fieldset>
        <fieldset>
            <legend>Instagram Pemesan/Acara</legend>
            <input
            type="text"
            value={igOrder}
            onChange={(e) => setIgOrder(e.target.value)}
            required
            />
        </fieldset>
        <fieldset>
            <legend>Alamat Lengkap Lokasi <span>*</span></legend>
            <textarea
            type="text"
            value={deliveryAddress}
            onChange={(e) => setDeliveryAddress(e.target.value)}
            required
            />
        </fieldset>
        <fieldset>
            <legend>Link Share Location</legend>
            <input
            type="text"
            value={deliveryMaps}
            onChange={(e) => setDeliveryMaps(e.target.value)}
            required
            />
        </fieldset>
        <div className='vw50'>
                <fieldset>
                    <legend>Tanggal <span>*</span></legend>
                    <input
                    type="date"
                    value={deliveryDate}
                    onChange={(e) => setDeliveryDate(e.target.value)}
                    required
                    />
                </fieldset>
                <fieldset>
                    <legend>Jam <span>*</span></legend>
                    <input
                    type="time"
                    value={deliveryTime}
                    onChange={(e) => setDeliveryTime(e.target.value)}
                    required
                    />
                </fieldset>
            </div>
        </>
    )}
    </>
  )
}

export default FormOrder