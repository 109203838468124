import React from 'react'

const FormRundown = ({ 
  capengName, 
  setCapengName, 
  eventDate, 
  setEventDate,
  izinMenikah,
  setIzinMenikah
 }) => {
  
  const handleEventDate = (e) => {
    const selectedDate = e.target.value;
    setEventDate(formatDate(selectedDate));
  };

  const formatDate = (dateString) => {
    // Membuat objek Date dari string yang dipilih
    const date = new Date(dateString);

    // Daftar nama hari dalam bahasa Indonesia
    const daysOfWeek = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

    // Daftar nama bulan dalam bahasa Indonesia
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 
      'September', 'Oktober', 'November', 'Desember'
    ];

    // Mengambil hari, tanggal, bulan, dan tahun
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0'); // Menambahkan leading zero
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Mengembalikan format yang diinginkan
    return `${dayOfWeek}, ${day} ${month} ${year}`;
  };

  return (
    <>
    <div>
        <label>Nama Capeng:</label>
        <input
        type="text"
        value={capengName}
        onChange={(e) => setCapengName(e.target.value)}
        required
        />
    </div>
    <div>
        <label>Tanggal Acara:</label>
        <p>Hari & Tanggal :
              <br>
              </br><strong>{eventDate}</strong>
            </p>
        <input
        type="date"
        onChange={handleEventDate}
        required
        />
    </div>
    <div>
        <label>Sample Izin Menikah:</label>
        <input
        type="text"
        value={izinMenikah}
        onChange={(e) => setIzinMenikah(e.target.value)}
        required
        />
    </div>
    </>
  )
}

export default FormRundown